<template>
  <div class="p-4 bg-white rounded-lg" v-if="isDataLoaded">
    <DataTable :table_state="table_state" :store_names="store_names">
      <template
        v-slot:topActions="{
          permissions,
          pageLoader,
          updateTable,
          selectedItems,
        }"
      >
        <div
          class="mr-md-2 mr-sm-1 mb-1 mr-0 pa-0"
          :class="{ 'col-6 px-8 mt-3': $vuetify.breakpoint.xsOnly }"
          v-if="permissions('sync')"
        >
          <button
            @click.prevent="() => handleResync(selectedItems, updateTable)"
            class="btn btn--export-filter font-weight-bolder"
          >
            <span class="svg-icon">
              <v-icon size="18">mdi-restart</v-icon>
            </span>

            <template>
              <span>Resync</span>
            </template>
          </button>
        </div>
      </template>
    </DataTable>
  </div>
</template>

<script>
import DataTable from "@/own/components/DataTable";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import {
  EXPORT_TABLE_DATA,
  SET_TABLE_FILTER,
  SET_TABLE_PAGE,
  SET_TABLE_PERPAGE,
  SET_TABLE_SORTBY,
  UPDATE_TABLE_DATA,
  UPDATE_TABLE_STRUCTURE,
} from "@/core/services/store/wesSPLTransactionMonitoring.module";
import Swal from "sweetalert2";
import ApiService from "@/core/services/api.service";
import SwalService from "@/core/services/swal.service";

export default {
  name: "WESSPLTransactionMonitoring",
  components: { DataTable },
  data: () => ({
    table_state: {
      UPDATE_TABLE_STRUCTURE: UPDATE_TABLE_STRUCTURE,
      UPDATE_TABLE_DATA: UPDATE_TABLE_DATA,
      SET_TABLE_SORTBY: SET_TABLE_SORTBY,
      SET_TABLE_FILTER: SET_TABLE_FILTER,
      SET_TABLE_PERPAGE: SET_TABLE_PERPAGE,
      SET_TABLE_PAGE: SET_TABLE_PAGE,
      EXPORT_TABLE_DATA: EXPORT_TABLE_DATA,
    },
    store_names: {
      getTableFilters: "getWESSPLTRMONITTableFilters",
      getTableState: "getWESSPLTRMONITTableState",
      getTableData: "getWESSPLTRMONITTableData",
      getTableHeaders: "getWESSPLTRMONITTableHeaders",
      getTableProperties: "getWESSPLTRMONITTableProperties",
      getTableExportUrl: "getWESSPLTRMONITExportUrl",
      getTablePermissions: "getWESSPLTRMONITTablePermissions",
      getUploadType: "getWESSPLTRMONITSUploadType",
    },
  }),
  methods: {
    async initTableContent() {
      await this.$store.dispatch(this.table_state.UPDATE_TABLE_STRUCTURE, {});
      await this.$store.dispatch(this.table_state.UPDATE_TABLE_DATA, {});
    },
    handleResync(selectedItems, updateTable) {
      if (selectedItems.length > 0) {
        this.$store.commit(SET_PAGE_LOADING, true);
        let ids = selectedItems.map((item) => item.id);
        let data = { ids: ids };
        ApiService.post("/wes/wesSplTransactionMonitoring/sync", data).then(
          ({ data }) => {
            if (data.success) {
              SwalService.successMessage({ html: data.message });
              updateTable();
            }
          }
        );
        // .finally(() => {
        //   this.$store.commit(SET_PAGE_LOADING, false);
        // });
      } else {
        Swal.fire({
          title: "Warning",
          text: "Please, select at least one order!",
          icon: "warning",
          showConfirmButton: false,
        });
      }
    },
  },
  computed: {
    isDataLoaded: function() {
      return this.$store.getters[this.store_names.getTableData];
    },
  },
  beforeMount() {
    this.$store.commit(SET_PAGE_LOADING, true);
    this.initTableContent();
  },
};
</script>
